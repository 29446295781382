import React from "react"
import { graphql, Link } from "gatsby"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"

import Seo from "../components/seo"
import BackButton from "../components/backButton"

import VacancyBackground from "../../static/images/vacancies.jpg"

import { LeftBackArrow } from "../shared/icons/icons"

const Career = ({ data: { job, site }, pageContext, location }) => {
  const lan = pageContext.locale
  return (
    <>
      <Seo
        lan={lan}
        title={job.title}
        description={job.description[0].children[0].text}
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}`}
        typ="article"
        titleTemplate={`${job.title} - Haackschubert`}
      />
      <div
        id="team"
        className="team-home-wrapper career-button no--margin-menu padding--huge-menu-sw"
      >
        <BackButton
          state={location.state}
          style={{ top: "140px", right: "calc(50% - 676px)" }}
          lan={lan}
          to={`/${lan}/career/`}
          text={lan === "en" ? "Career" : "Karriere"}
        />
        <div
          style={{
            maxWidth: "500px",
            maxHeight: "595px",
          }}
          className="profileimage m-auto"
        >
          <img
            className="br-20"
            loading="lazy"
            src={VacancyBackground}
            width="100%"
            height="100%"
            alt="img"
          />
          <div className="triangle"></div>
        </div>
        <div className="row">
          <div className="col col-1">
            <span className="vacancy-intro">
              {lan === "en"
                ? "To strengthen our team, we are now looking for:"
                : "Zur Verstärkung unseres Teams suchen wir ab sofort:"}
            </span>
            <h1 className="career-head">
              {hyphenated(job.title, { language: de })} {job.gender}
            </h1>
            {job.description &&
              job.description.map(res => {
                return res.children.map((re, index) => {
                  return (
                    <h6 className="career-desc" key={index}>
                      {lan === "en"
                        ? hyphenated(re.text)
                        : hyphenated(re.text, { language: de })}
                    </h6>
                  )
                })
              })}
          </div>
        </div>
        <div className="row" style={{ display: "block" }}>
          <div className="col col-1">
            <ul>
              <li>
                <h6>
                  {lan === "en"
                    ? "Your area of ​​responsibility"
                    : "Ihr Aufgabengebiet"}
                </h6>
              </li>
              {job.responsibility.map((res, index) => (
                <li key={index}>
                  {lan === "en"
                    ? hyphenated(res)
                    : hyphenated(res, { language: de })}
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-1">
            <ul>
              <li>
                <h6>{lan === "en" ? "Your Profile" : "Ihr Profil"}</h6>
              </li>
              {job.profile.map((pro, index) => (
                <li key={index}>
                  {lan === "en"
                    ? hyphenated(pro)
                    : hyphenated(pro, { language: de })}
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-1">
            <ul>
              <li>
                <h6>{lan === "en" ? "Our Range" : "Unser Angebot"}</h6>
              </li>
              {job.range.map((ran, index) => (
                <li key={index}>
                  {lan === "en"
                    ? hyphenated(ran)
                    : hyphenated(ran, { language: de })}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col col-1">
            <strong>
              {job.notice.map(not => {
                return not.children.map(no => {
                  return no.text
                })
              })}
              <br />
              HaackSchubert Partnerschaftsgesellschaft mbB
              <br />
              {job.hrName}
              <br />
              Telefon: {job.attendPhone}
              <br />
              Mail:{" "}
              <a
                href={`mailto:${job.attendEmail}`}
                target="_blank"
                rel="noreferrer"
              >
                {job.attendEmail}
              </a>
            </strong>
          </div>
        </div>
        <div className="buttons">
          <Link
            state={location.state}
            className="button close contact"
            to={`/${lan}/career/`}
          >
            {lan === "en" ? "Back to Career" : "Zurück zu Karriere"}
            <div
              style={{
                top: "10px",
              }}
            ></div>
            <span
              style={{
                top: "1px",
              }}
              className="icon-arrow_left"
            >
              <LeftBackArrow fill="white" />
            </span>
          </Link>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    job: sanityJob(slug: { current: { eq: $slug } }) {
      title
      gender
      responsibility
      range
      profile
      description {
        children {
          text
        }
      }
      notice {
        children {
          text
        }
      }
      hrName
      attendPhone
      attendEmail
      slug {
        current
      }
    }
  }
`

export default Career
